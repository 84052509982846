
import { defineComponent, onMounted } from "vue";
import KTTableWidgetDataFromPLorBSSME from "@/components/widgets/tables/WidgetDataFromPLorBSSME.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "daily-cash-flow",
  components: {
    KTTableWidgetDataFromPLorBSSME,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Data From P&L or BS SME", ["Financial Statements","Financial Health Ratio"]);
    });
  },
});
