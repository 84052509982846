
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ElCurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
name: "healthsheet1new",
components: {

  Form,
  ElCurrencyInputNoSymbol,
},
async mounted() {
  this.init();
  this.initDays();
},
data() {
  const statements= {
    incomeStatement: {
      label: "Penyata Untung Rugi",
      child: {
        totalSales: {
          label: "(1) Jumlah Jualan",
          value: [],
        },
        totalCostOfSales: {
          label: "(2) Jumlah kos Jualan ",
          value: [],
        },
        grossFit: {
          label: "(3) Untung Kasar",
          value: [],
        },
        totalOperatingExpenses: {
          label: "(4) Jumlah Perbelanjaan Operasi",
          value: [],
        },
        totalInterestCharges: {
          label: "(5) jumlah Cas Faedah",
          value: [],
        },
        profitBeforeInterestAndTax: {
          label: "(6) Untung Sebelum Caj Faedah Dan Bayaran Cukai",
          value: [],
        },
        profitAfterTax: {
          label: "(7) Untung Bersih",
          value: [],
        },
        numberOfDaysOfSales: {
          label: "(8) Jumlah Hari Jualan",
          value: [],
        },
      }
    },
    financialPosition: {
      label: "Kunci Kira-Kira",
      child: {
        totalAssets: {
          label: "(1) Jumlah Aset",
          value: [],
        },
        totalCurrentAssets: {
          label: "(2) Jumlah Aset Semasa",
          value: [],
        },
        totalCurrentLiabilies: {
          label: "(3) Jumlah Stok Akhir",
          value: [],
        },
        totalClosingStocks: {
          label: "(4) Jumlah Penghutang",
          value: [],
        },
        totalDebtors: {
          label: "(5) Jumlah Liabiliti Semasa",
          value: [],
        },
        totalCreditors: {
          label: "(6) Jumlah Pemiutang",
          value: [],
        },
        totalShareholderEquity: {
          label: "(7) Jumlah Pemegang Ekuiti Saham",
          value: [],
        },
        totalDebtHolding: {
          label: "(8) Jumlah Hutang (cth, Pembiayaan Bank)",
          value: [],
        },
      }
    }
  }
  return {
    statements,
  };
},
setup() {
  return {};
},
methods: {
  init() {
    setCurrentPageBreadcrumbs("Data from P&L or BS", ["Financial Statement"]);
  },

    onSubmit(value) {
      Swal.fire({
        text: "All is cool! Now you submit this form",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(() => {
        console.log(value);
        // window.location.reload();
      });
    },
  initDays() {
    for (let key of Object.keys(this.statements.incomeStatement.child)) {
      let incomeStatementChild = this.statements.incomeStatement.child;
      for (let index =0; index < 1; index++) {
        incomeStatementChild[key].value.push(0);
      }
    }

    for (let key of Object.keys(this.statements.financialPosition.child)) {
      let financialPositionChild = this.statements.financialPosition.child;
      for (let index =0; index < 1; index++) {
        financialPositionChild[key].value.push(0);
      }
    }
  },
},
});
